import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Form, Input } from 'antd';
import { isNil } from 'lodash-es';
import { Button } from 'components/UIExternal';
import { EXTERNAL_PAYMENT_PROVIDERS } from 'enums';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions } from '../styles';
import { rules } from 'utils/inputValidation';
import { BaseCheckbox } from 'components/ui/Checkboxes';

export const ExternalPaymentProviders = ({ data, onUpdate }) => {
  if (isNil(data)) return <FormBoxLoader number={1} />;

  const initialValues = {
    currency: data[EXTERNAL_PAYMENT_PROVIDERS.COINPAYMENTS_CURRENCY],
    timeoutHours: Number(data[EXTERNAL_PAYMENT_PROVIDERS.COINPAYMENTS_REQUEST_TIMEOUT]) / 60 / 60,
    isStripeActive: data[EXTERNAL_PAYMENT_PROVIDERS.STRIPE_PAYMENT_METHOD_ACTIVE] === '1',
    stripeCurrency: data[EXTERNAL_PAYMENT_PROVIDERS.STRIPE_CREATE_PAYMENT_INTENT_CURRENCY],
    stripeExpirationDays: data[EXTERNAL_PAYMENT_PROVIDERS.STRIPE_PAYMENT_INTENT_EXPIRATION_DAYS],
  };

  const handleSubmit = values => {
    const timeoutInSeconds = values.timeoutHours * 60 * 60;
    onUpdate({
      [EXTERNAL_PAYMENT_PROVIDERS.COINPAYMENTS_CURRENCY]: values.currency,
      [EXTERNAL_PAYMENT_PROVIDERS.COINPAYMENTS_REQUEST_TIMEOUT]: timeoutInSeconds,
      [EXTERNAL_PAYMENT_PROVIDERS.STRIPE_PAYMENT_METHOD_ACTIVE]: values.isStripeActive ? '1' : '0',
      [EXTERNAL_PAYMENT_PROVIDERS.STRIPE_CREATE_PAYMENT_INTENT_CURRENCY]: values.stripeCurrency,
      [EXTERNAL_PAYMENT_PROVIDERS.STRIPE_PAYMENT_INTENT_EXPIRATION_DAYS]: values.stripeExpirationDays
    });
  };

  return (
    <Form
      onFinish={handleSubmit}
      layout='vertical'
      initialValues={initialValues}
      err
    >
      <Form.Item
        className={configurationSettingsInput}
        label={getText('coinpaymentsCurrency')}
        name='currency'
        required
        rules={[
          {
            required: true,
            message: getText('fieldIsRequired'),
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('coinpaymentsTimeoutHours')}
        name='timeoutHours'
        required
        rules={[
          {
            validator: (_, value) => rules.minMaxAsync(1, 168, value)
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('stripeCurrency')}
        name='stripeCurrency'
        required
        rules={[
          {
            required: true,
            message: getText('fieldIsRequired'),
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('stripeExpirationDays')}
        name='stripeExpirationDays'
        required
        rules={[
          {
            required: true,
            message: getText('fieldIsRequired'),
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        name='isStripeActive'
        valuePropName="checked"
      >
        <BaseCheckbox>{getText('isStripeActive')}</BaseCheckbox>
      </Form.Item>
      <div css={submitActions}>
        <Button small type="secondary">
          {getText('submit')}
        </Button>
      </div>
    </Form>
  );
};

ExternalPaymentProviders.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};
